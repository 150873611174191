var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticStyle:{"display":"flex","margin":"20px","background-color":"white","width":"1100px"}},[_vm._m(1),_c('el-radio-group',{staticStyle:{"margin-top":"10px"},on:{"change":_vm.getSchoolList},model:{value:(_vm.queryForm.addressId ),callback:function ($$v) {_vm.$set(_vm.queryForm, "addressId", $$v)},expression:"queryForm.addressId "}},[_c('el-radio-button',{attrs:{"label":""}},[_vm._v("全部")]),_vm._l((_vm.addressList),function(item,index){return _c('el-radio-button',{attrs:{"label":item.id}},[_vm._v(_vm._s(item.address))])})],2)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin":"20px","width":"1100px"}},[_c('el-table',{staticStyle:{"width":"900px"},attrs:{"data":_vm.tableData,"stripe":"","header-cell-style":{
        background: '#ffffff',
        color: '#1e8afc',
        fontWeight: '400',
        fontSize: '16px',
        }}},[_c('el-table-column',{attrs:{"label":"学校列表","width":"210px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"padding":"15px 0"}},[_c('el-image',{staticStyle:{"height":"120px","width":"200px"},attrs:{"src":scope.row.img}})],1)]}}])}),_c('el-table-column',{attrs:{"width":"650px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"height":"130px"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(_vm._s(scope.row.name))]),_c('div',{staticStyle:{"margin-top":"15px"}},[_vm._v("简介:"+_vm._s(scope.row.context))])])]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-button',{staticClass:"el-icon-search",staticStyle:{"border-radius":"30px","width":"100px","float":"right","margin-right":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.openSchool(scope.row.hyperlink)}}},[_vm._v("学校主页 ")])],1)]}}])})],1)],1),_c('div',{staticClass:"center"},[_c('el-pagination',{staticClass:"center",attrs:{"background":"","current-page":_vm.queryForm.current,"page-size":_vm.queryForm.size,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"1200px"}},[_c('div',{staticStyle:{"color":"gray","margin":"20px"}},[_vm._v("广州高考网 > 走进名校")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"color":"gray","background-color":"#f3f3f3","padding":"10px"}},[_c('span',{staticStyle:{"line-height":"30px"}},[_vm._v("地区：")])])
}]

export { render, staticRenderFns }