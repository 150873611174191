<template>
  <div>
    <div style="width: 1200px">
      <div style="color: gray;margin: 20px">广州高考网 > 走进名校</div>
    </div>
    <div style="display: flex;margin: 20px;background-color: white;width: 1100px">

      <div style="color: gray;background-color: #f3f3f3;padding: 10px"><span style="line-height: 30px">地区：</span>
      </div>
      <el-radio-group v-model="queryForm.addressId " @change="getSchoolList" style="margin-top: 10px;">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button v-for="(item,index) in addressList" :label="item.id">{{ item.address }}</el-radio-button>
      </el-radio-group>
    </div>
    <div style="display: flex;justify-content: center;margin: 20px;width: 1100px">
      <el-table
          :data="tableData"
          stripe
          style="width: 900px;"
          :header-cell-style="{
          background: '#ffffff',
          color: '#1e8afc',
          fontWeight: '400',
          fontSize: '16px',
          }"
      >
        <el-table-column
            label="学校列表"
            width="210px"
        >
          <template scope="scope">
            <div style="padding: 15px 0 ">
              <el-image style="height: 120px;width: 200px;" :src="scope.row.img"></el-image>
            </div>
          </template>

        </el-table-column>

        <el-table-column

            width="650px"
        >
          <template scope="scope">
            <div style="height: 130px">
              <div style="font-weight: bold;font-size: large;">{{ scope.row.name }}</div>
              <div style="margin-top: 15px">简介:{{ scope.row.context }}</div>
            </div>
          </template>

        </el-table-column>
        <el-table-column


        >
          <template scope="scope">
            <div>
              <el-button type="primary" class="el-icon-search"
                         style="border-radius: 30px;width: 100px;float:right;margin-right: 20px"
                         @click="openSchool(scope.row.hyperlink)">学校主页
              </el-button>
              <!--             <div>{{scope.row.context}}</div>-->
            </div>
          </template>

        </el-table-column>

      </el-table>

    </div>
    <div class="center">
      <el-pagination
          class="center"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryForm.current"
          :page-size="queryForm.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
      />
    </div>
  </div>
</template>

<script>
import {fetchPage, getAddress} from "../../../api/front/home";

export default {
  props: {
    dataType: {type: String}
  },

  data() {
    return {
      tableData: [],
      queryForm: {
        current: 1,
        size: 10,
        addressId: ""
      },
      addressList: [],
      total: 0,
      dataType: ''
    }
  },
  methods: {
    getAddressList() {
      getAddress().then(res => {
        this.addressList = res.data.data;
      })
    },
    getSchoolList() {
      fetchPage(Object.assign(this.queryForm)).then(res => {
        console.log(res)
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    // 打开学校界面
    openSchool(url) {
      window.open(url)
    },
    handleSizeChange(val) {
      this.queryForm.size = val;
      this.queryForm.current = 1;
      this.getSchoolList();
    },
    handleCurrentChange(val) {
      this.queryForm.current = val;
      this.getSchoolList();
    },
  },


  mounted() {
    this.getAddressList();
    this.getSchoolList();

  }
}
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;

}

::v-deep.el-radio-button {
  //margin-right: 15px;
  //border-radius:4px;
  .el-radio-button__inner { //修改按钮样式
    width: 80px;
    height: 30px;
    background: #ffffff;
    color: #333;
    border: 0 !important;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner { // 修改按钮激活样式
    color: #fff;
    background-color: #5eacf9;
    border-color: #5eacf9;
    box-shadow: #5eacf9;
  }
}

</style>
